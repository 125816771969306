/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FcGoogle } from 'react-icons/fc';
import { toast } from 'react-toastify';

import { useCookies } from 'react-cookie';

import { HorizontalLine } from '../../components/HorizontalLine';

import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { AuthState } from '../../modules/auth/AuthState';
import { AuthApi, AuthApiV2, DatabaseApi } from '../../api/client';
import { FormValues } from './Auth';
import useAuth from '../../hooks/auth';
import { useEffect } from 'react';
import { track } from '../tracking';

const checkAuthorized = async ({ token }: { token: string }) => {
  try {
    const client = DatabaseApi();

    const headers = {
      Authorization: `Basic ${token}`,
    };
    const resp = await client.databasesList({
      // @ts-ignore
      headers,
    });
    return resp.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error', error);
  }

  return null;
};


const Signin = ({
  googleLogin
}: {
  googleLogin: () => void
}) => {
  const [cookies, setCookie] = useCookies(['auth']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const back = searchParams.get('back');
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: typeof cookies.auth === 'object' ? cookies.auth : {},
  });
  const [auth, setAuth] = useRecoilState(AuthState);

  useEffect(() => {
    const refreshAccessToken = async () => {
      const client = AuthApi();
      try {
        const resp = await client.postAuth();
        if (resp && resp.data.accessToken && resp.data.user?.id) {
          setAuth({
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
            tenant: resp.data.user.tenant,
            subscription: resp.data.subscription,
          });
        } else {
          setAuth(null);
        }

        setTimeout(() => {
          // onComplete();
          // navigate('/v2/explore')
        }, 1);
      } catch (error) {
        setAuth(null);
      }
    };
    refreshAccessToken();
  }, []);


  const navigateToVerify = (emailAddress: string) => {
    navigate(`/v2/auth/verify/${emailAddress}`)
  }

  const onSubmit = async (data: FormValues) => {

    if (!data.username || !data.password) {
      toast.warn('Please enter your email and password.', {
        autoClose: 2000,
        position: 'top-center',
      })
      return;
    }

    const client = AuthApiV2();

    try {
      const res = await client.signinCreate({
        identity_handle: data.username,
        password: data.password
      })

      track('Auth - Login', {
        type: 'Email',
        isZettaBlockUser: res?.data?.user?.email?.includes('zettablock'),
        ...auth,
        tenant: auth?.tenant || 'community',
        isV2: true
      })

      navigate('/v2/explore')
      toast.success('Signin successful.', {
        autoClose: 2000,
        position: 'top-center',
      })
    } catch (error: any) {
      if (error?.response?.data?.message) {
        // 
        switch (error?.response?.data?.message) {
          case "password is not matched: crypto/bcrypt: bcrypt hashes must start with '$', but hashedSecret started with '&'":
            toast.warn('Password not matched. Please try again.', {
              autoClose: 2000,
              position: 'top-center',
            })
            return;
          case 'user is not found':
            toast.warn('User not found. Please try again.', {
              autoClose: 2000,
              position: 'top-center',
            })
            return;
          case 'the user email had signed up and waiting for activation':
            toast.warn('User not activated. Please check your email to verify your account.', {
              autoClose: 2000,
              position: 'top-center',
            })
            navigateToVerify(data.username || '')
            return;
          default:
            break;
        }
      }
      // eslint-disable-next-line no-console
      console.warn('error', error)
      toast.warn((error as any).message || 'Something went wrong during signup. Please try again.')
    }
  }

  return <div className='bg-[#fff] mt-8 pb-10'>
    <div className='bg-[#FBFCFD] px-8 py-6 flex justify-between items-center'>
      <span className=' font-bold'>
        Login
      </span>

      <span className='text-sm'>
        Don&rsquo;t have an account?{' '} <Link to="/v2/auth/register" className='text-[#263DFF] hover:cursor-pointer font-bold'>Sign up</Link>
      </span>
    </div>
    <form
      onSubmit={handleSubmit(onSubmit)} className='w-full px-8'>
      <div className='form-control w-full mt-6'>
        <label className='label'>
          <span className='label-text'>Email</span>
        </label>
        <input
          placeholder='email@example.com'
          type='text'
          className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
          {...register('username')}
        />
      </div>
      <div className='form-control w-full mt-4'>
        <label className='label'>
          <span className='label-text'>Password</span>
        </label>
        <input
          placeholder='**********'
          type='password'
          className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
          {...register('password')}
        />
      </div>

      <button className='btn btn-primary bg-[#263DFF] mt-10 w-full'>
        Login
      </button>
    </form>
    {/* <div className='text-center mt-5 text-sm'>
    {"Don't have an account? "}
    <Link to='/auth/register' className='text-[#263DFF]'>
      Sign Up
    </Link>
  </div> */}
    <div className='text-center mt-5 text-sm px-8 font-semibold'>
      By logging in, you are agreeing to the{' '}
      <a
        className='text-[#263DFF]'
        href='https://www.zettablock.com/terms'
        target='_blank'
        rel='noreferrer'
      >
        Terms of Service
      </a>{' '}
      and confirm that you have read the{' '}
      <a
        className='text-[#263DFF]'
        href='https://www.zettablock.com/privacy'
        target='_blank'
        rel='noreferrer'
      >
        Privacy Policy
      </a>
      .
    </div>
    <div className='px-8 mt-5'>
      <HorizontalLine className='h-0 mb-5' />
      <button
        className='btn btn-outline gap-5 w-full normal-case text-xl'
        onClick={() => googleLogin()}
      >
        <FcGoogle size='1.5rem' />
        Continue with Google
      </button>
    </div>
  </div>
}

export default Signin;